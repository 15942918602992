import React, { useCallback, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { DateCalendar, PickersDay } from '@mui/x-date-pickers';

import { Button, Popover, Typography } from '@mui/material';
import { Event } from '@mui/icons-material';

const useStyles = makeStyles({ name: 'Date' })((theme, _params, classes) => ({
  input: { '& .MuiInputBase-input': { padding: 9, paddingRight: 0 } },
  popoverHeader: { padding: theme.spacing(2, 2, 0, 3) },
  popoverSubheader: { padding: theme.spacing(0, 2, 0, 3) },
  day: {
    margin: 0,
    borderRadius: theme.spacing(1),
    transition: theme.transitions.create(['border-radius', 'background-color'], {
      duration: theme.transitions.duration.short,
    }),
    [`&.${classes.between}`]: { backgroundColor: theme.palette.primary.lightOpaque },
    [`&.${classes.hover}`]: { backgroundColor: theme.palette.primary.light },
  },
  between: { borderRadius: 0 },
  from: {
    [`&.${classes.after}`]: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
    [`&.${classes.before}`]: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
  },
  hover: {
    borderRadius: theme.spacing(1),
    [`&.${classes.after}`]: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
    [`&.${classes.before}`]: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
  },
  '$from, $hover': {},
  before: {},
  after: {},
  date: {},
}));

const DateRangePicker = ({ value, setValue, initialValue, slotProps = {} }) => {
  const { classes: styles, cx } = useStyles();
  const { from, to } = { ...value };

  const { dateRangePicker: dateRangePickerProps } = slotProps;

  const [open, setOpen] = useState(0);

  const setFrom = useCallback((from) => setValue((old) => ({ ...old, from })), [setValue]);
  const setTo = useCallback((to) => setValue((old) => ({ ...old, to })), [setValue]);

  const [hover, setHover] = useState();

  const dateRef = useRef();

  const handleClick = useCallback(
    (date) => {
      if (open === 1) {
        setFrom(date);
        setOpen(2);
      } else {
        if (date?.isBefore(from)) {
          setTo(from);
          setFrom(date);
        } else {
          setTo(date);
        }
        setOpen(0);
      }
    },
    [from, open, setFrom, setTo]
  );

  const openPopover = useCallback(() => {
    setValue(initialValue);
    setHover();
    setOpen(1);
  }, [setValue, initialValue]);

  const CustomPickersDay = ({ ...pickersDayProps }) => {
    const { day: date } = { ...pickersDayProps };

    const isBetween =
      !!from && !!hover && date.isBetween(hover?.isBefore(from) ? hover : from, hover?.isBefore(from) ? from : hover);

    return (
      <PickersDay
        {...pickersDayProps}
        className={cx(
          styles.day,
          isBetween && styles.between,
          date?.isSame(from) && styles.from,
          date?.isSame(hover) && styles.hover,
          !!from && date?.isSame(hover) && date?.isBefore(from) && styles.before,
          !!from && date?.isSame(hover) && date?.isAfter(from) && styles.after,
          !!from && date?.isSame(from) && date?.isBefore(hover) && styles.before,
          !!from && date?.isSame(from) && date?.isAfter(hover) && styles.after
        )}
        selected={!!from && date?.isSame(from)}
        onClick={() => handleClick(date)}
        onMouseEnter={() => setHover(date)}
        onMouseLeave={() => setHover(undefined)}
      />
    );
  };

  return (
    <>
      <Button variant="outlined" onClick={openPopover} ref={dateRef} startIcon={<Event />} fullWidth>
        <span>
          <span className={styles.date}>{from?.format('L') || 'Date from'}</span>
          {' - '}
          <span className={styles.date}>{to?.format('L') || 'Date To'}</span>
        </span>
      </Button>
      <Popover
        open={!!open}
        anchorEl={dateRef.current}
        onClose={() => {
          open !== 2 && setOpen(0);
        }}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        <Typography className={styles.popoverHeader} variant="subtitle1" component="h1">
          Select Date Range
        </Typography>
        <Typography variant="body2" className={styles.popoverSubheader}>{`Set 'date ${
          open === 1 ? 'from' : 'to'
        }'`}</Typography>
        <DateCalendar onChange={() => {}} slots={{ day: CustomPickersDay }} disableFuture {...dateRangePickerProps} />
      </Popover>
    </>
  );
};

export default DateRangePicker;
